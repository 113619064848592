// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: NunitoSans-ExtraBold;
  font-style: bold;
  src: url("./assets/fonts/NunitoSans-ExtraBold.ttf");
}

// ion-card {
//     border-radius: 10px !important;
// }
.cheer-modal {
  --height: 70%;
  align-items: flex-end;
}
.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.session-popover .popover-content {
  width: 80% !important;
  height: 400px;
}

.session-popover::part(content) {
  width: 80% !important;
  height: 400px;
}
.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}

ion-icon {
  &[name*="custom-"] {
    height: 1em;
    width: 1em;
  }
}

.invalid-feedback {
  color: red;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1em;
}

.is-invalid {
  color: red;
}



.week-day-circle {
  display: block;
  border-radius: 50%;
  background-color: #f1f7ef;
  height: 30px;
  width: 30px;
  text-align: center;
  padding-top: 5px;
  position: relative;
  border: 1px dot;
}

.habitlist-weekview-css {
  width: 100%;
}

.day-header-week {
  // background-color: lightcoral;
  text-transform: uppercase;
  color: #f4a933;
  padding-bottom: 10px;
}

.day .circle label {
  color: #f4a933;
  padding: 10px;
}

.custom-popover .popover-content {
  width: 80%;
}

//set tour css class

// .tour-content {
//     min-height: inherit !important;
//     padding-bottom: 10px !important;
// }

// .next-button {
//     background-color: var(--ion-color-primary) !important;
//     border-radius: 5px !important;
//     padding: 5px !important;
// }

// .skip-button {
//     display: none;
// }

// .tour-step {
//     min-width: 300px !important;
// }

// .tour-block,
// .guided-tour-spotlight-overlay {
//     border-radius: 5px;
// }

//set modal css
.video-modal {
  .modal-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40px;
    transform: translate3d(0, 0, 0);
    border-radius: 30px 30px 0 0;
  }
}

.video-modal {
  .ion-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40px;
    transform: translate3d(0, 0, 0);
    border-radius: 30px 30px 0 0;
  }
}

.swal-height {
  height: 100%!important;
}

/*
*set css for video
*/
.mask-image {
  // set image format
  mask: linear-gradient(to top, transparent, #000 70%);
}

.video-description {
  display: block;
  position: absolute;
  bottom: 10px;
  overflow: hidden;
}
ion-label{
  color: #444444 !important;
}
.ion-label-border {
  min-width: 60px;
  border: solid 1px #eeeff1;
  padding: 5px;
  border-radius: 5px;
  background-color: #eeeff1;
}

.ion-label-font-title {
  font-family: NunitoSans-ExtraBold;
}

.ion-label-font {
  font-family: NunitoSans-ExtraBold;
  font-size: 8pt;
  color: #3e2600;
}

.video-paly-button {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-size {
  /* Height option*/
  width: 100%;
  overflow: hidden;
  // border-radius:0px opx 10px 10px;
}

/*
*set css for ion-header
*/

ion-title {
  padding-left: 20px;
  font-family: NunitoSans-ExtraBold;
  color: #444444 !important;
}

ion-select::part(placeholder) {
  color: #000000;
  opacity: 1;
}

ion-select::part(text) {
  color: #000000;
}

ion-header ion-toolbar {
  --background: var(--ion-color-light);
  --min-height: 55px;
}

ion-back-button::part(text) {
  font-size: x-large;
  padding-left: 10px;
}

/*
*set css for ion-list-header
*/
ion-list-header ion-label {
  font-family: NunitoSans-ExtraBold;
  font-size: 18.4pt;
}

//set full size of image in ion-thumbnail
ion-thumbnail {
  min-height: 90px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.text-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bold-title {
  font-weight: 600 !important;
  color: #737373;
}

.grey-text {
  color: #737373;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-16 {
  padding-left: 16px;
}

.ml-16 {
  margin-left: 16px;
}
.mt--30 {
  margin-top: -30px !important;
}

.p-20 {
  padding: 20px;
}

.swal2-styled.swal2-confirm {
  background-color: #A6D143 !important;
}

.small-rnd-button{
  --border-radius : 50px;
  
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


// Hide all elements
body.barcode-scanner-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

// Show only the barcode scanner modal
.barcode-scanner-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanner-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}

.rounded-img{
  border-radius: 10px;
}
p, h1, h2, h3, h4, h5, h6, span, a{
  color: #444444 !important;
}

ion-button{
  color: #444444;
}

ion-back-button{
  color: #444444;
}

.grey-icon{
  color: #444444 !important;
}

.iti {
  width: 100%;
}

.iti input[type='text'],
.iti input[type='tel'] {
  border: none;
  border-bottom: 1px solid #9d9d9d;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  padding: 16px;
}

.iti input[type='text']:focus,
.iti input[type='tel']:focus {
  border-bottom: 2px solid #bcbcbc;
}

